<template>
  <div id="networkdetailcompnent">
    <el-row>
      <el-col :span="1">
        <div class="grid-content bg-purple"></div>
      </el-col>
      <el-col :span="14">
        <div id="mynetwork" style="border: 1px solid black"></div>
      </el-col>
      <el-col :span="1"></el-col>
      <el-col :span="7">
        <div class="grid-content bg-purple"></div>
        <div style="margin-top: 35px">
          <el-input placeholder="search items" v-model="selectedItem" class="input-with-select"
            @keyup.enter.native="search" size="large">
            <template #append>
              <el-button icon="el-icon-search" @click="search"></el-button>
            </template>
          </el-input>
        </div>
      </el-col>
      <el-col :span="1">
        <div class="grid-content bg-purple"></div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import request from '@/network/request'
  import $ from 'jquery'
  import vis from "vis-network/dist/vis-network.min.js"
  import "vis-network/dist/dist/vis-network.min.css"
  import ChemDoodle from "../../mymodules/ChemDoodleWeb-9.2.0/install/ChemDoodleWeb.js"
  // const vis = require("vis-network/dist/vis-network.min.js")
  let canvas
  let context1
  export default {
    name: 'NetworkDetailCompnent',
    data () {
      return {
        networkkeggDict: {},
        selectedMetaboliteInfo: "",
        selectedItem: "TraesCS5D02G173800",
        networkData: ""
      }
    },
    methods: {
      networkFresh (params) {
        this.networkSearch(params.nodes[0])
      },
      search () {
        this.networkSearch(this.selectedItem)
      },
      networkSearch (params) {
        this.selectedItem = params
        let seachParams = { genes: this.selectedItem, searchItem: "job" }
        // seachParams = { species: this.species, chromosome: this.chromosome, left: this.intervel.left * 10 ** 6, right: this.intervel.right * 10 ** 6 }
        request({
          url: 'additional/wpmrn/',
          params: seachParams,
        }).then(res => {
          this.networkData = res
          this.head = "Network Details"
        }).catch(err => {
          console.log(err);
        });
      },
      plotNetwork2 (edgedata, nodedata) {
        // var colorPlate = ["#8dd3c7", "#bebada", "#fb8072", "#80b1d3", "#fdb462", "#b3de69", "#fccde5", "#d9d9d9", "#bc80bd", "#ccebc5", "#ffed6f", "#ffffb3"]
        // var node = new Set()
        // var nodeKeggCompound = []
        var edgeList = []
        // this.networkkeggDict = {}
        // for (let i = 0; i < networkkegg.length; i++) {
        //   this.networkkeggDict[networkkegg[i].keggId] = networkkegg[i].metabolite
        //   nodeKeggCompound.push(networkkegg[i].metabolite)
        // }
        for (let i = 0; i < edgedata.length; i++) {
          edgeList.push({ "from": edgedata[i].nodefrom, "to": edgedata[i].nodeto, "label": Number(edgedata[i].r).toFixed(2) })
        }

        var nodeList = []
        for (let i = 0; i < nodedata.length; i++) {
          if (nodedata[i].type2 == "gene") {
            nodeList.push({
              "id": nodedata[i].node,
              "label": nodedata[i].node,
              "group": "gene",
              color: {
                border: '#fdb462',
                background: '#fdb462',
                highlight: {
                  border: '#ffed6f',
                  background: '#ffed6f'
                },
              },
            })
          } else {
            nodeList.push({
              "id": nodedata[i].node,
              "label": nodedata[i].node,
              "group": "hormone",
              color: {
                border: '#71c68d',
                background: '#71c68d',
                highlight: {
                  border: '#ffed6f',
                  background: '#ffed6f'
                },
              },
            })
          }
        }
        var options = {
          nodes: {
            borderWidth: 1,
            font: {
              size: 10
            },
          },
          edges: {
            // color: 'lightgray',
            // color: "#8dd3c7",
            arrows: {
              to: {
                enabled: false,
                type: "arrow",
              },
            },
            width: 3,
            font: {
              size: 18
            },
          },
          // physics: true,
          physics: {
            stabilization: false,
            barnesHut: {
              gravitationalConstant: -1000,
              springConstant: 0.01,
              springLength: 50,
              avoidOverlap: 0.0,
              centralGravity: 0.5,
            },
          },
          // physics: {
          //   stabilization: false,
          //   barnesHut: {
          //     gravitationalConstant: -80000,
          //     springConstant: 0.001,
          //     springLength: 200,
          //   },
          // },
          layout: {
            // hierarchical: {
            //   enabled: true,
            // },
            randomSeed: 2,
            improvedLayout: false,
          },
          interaction: {
            tooltipDelay: 200,
            hideEdgesOnDrag: true,
            // hideEdgesOnZoom: true,
          },
        }

        var container = document.getElementById("mynetwork");
        var data = {
          nodes: nodeList,
          edges: edgeList,
        };
        let network = new vis.Network(container, data, options)
        network.on("doubleClick", this.networkFresh)
      }
    },
    props: {
      head: {
        type: String,
        default: 'Requesting !',
      },
    },
    components: {

    },
    created () {
      this.networkSearch(this.selectedItem)
      // this.plotNetwork2(this.networkData.WpmrnEdge, this.networkData.WpmrnNode)
    },
    mounted () {
      // window.vue = this

    },
    updated () {
    },
    watch: {
      networkData: {
        handler (val, oldVal) {
          this.plotNetwork2(val.WpmrnEdge, val.WpmrnNode)
        }
      }
    }

  }
</script>

<style scoped lang="scss">
  .el-main {
    background-color: #e9eef3;
    color: #333;
    text-align: center;
    /*    max-height: 1000px;
    overflow-y: scroll;*/
  }

  .el-select .el-input {
    width: 130px;
  }

  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

  #mynetwork {
    width: 100%;
    height: 800px
  }
</style>