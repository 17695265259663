<template>
  <div id="networkdetail">
    <MyheadCompnent></MyheadCompnent>
    <!-- <MetaboliteBrowseCompnentSearch :headT='head'></MetaboliteBrowseCompnentSearch> -->
    <WpmrnCompnent :head='head'></WpmrnCompnent>
    <MyfooterCompnent></MyfooterCompnent>
  </div>
</template>

<script>
  import WpmrnCompnent from '@/components/Browse/WpmrnCompnent.vue'
  import MyheadCompnent from '@/components/Common/MyheadCompnent.vue'
  // import MetaboliteBrowseCompnentSearch from '@/components/Browse/MetaboliteBrowseCompnentSearch.vue'
  import MyfooterCompnent from '@/components/Common/MyfooterCompnent.vue'
  import request from '@/network/request'
  export default {
    name: 'NetworkDetail',
    data () {
      return {
        seachParams2: {
          format: 'json',
          searchItem: {
            genes: ["TraesCS5D02G173800"]
          },
        },
        networkData: '',
        head: "Requesting !",
      }
    },
    methods: {
      getSearchData () {
        var seachParams = { genes: "TraesCS5D02G173800,Auxin", searchItem: "job" }
        // seachParams = { species: this.species, chromosome: this.chromosome, left: this.intervel.left * 10 ** 6, right: this.intervel.right * 10 ** 6 }

        request({
          url: 'additional/wpmrn/',
          params: seachParams,
        }).then(res => {
          // console.log(res);
          this.networkData = res
          this.head = "Network Details"
        }).catch(err => {
          console.log(err);
        });
      }
    },
    components: {
      WpmrnCompnent,
      MyheadCompnent,
      MyfooterCompnent,
      // MetaboliteBrowseCompnentSearch,
    },
    created () {
      // this.seachParams.searchItem = this.$route.params.searchItem
      this.head = "Network Details"
      // this.getSearchData()
    },
    // 相同路由，参数不一样，路由不跳转，但是beforeRouteUpdate可以监控
    // beforeRouteUpdate (to, from, next) {
    //   this.seachParams.searchItem = to.params.searchItem
    //   this.getSearchData()
    //   next();
    // },
    updated () {
    },
  }
</script>

<style scoped lang="scss">
</style>